import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-ad-hoc-push-notifications"
    }}>{`Configure Ad-hoc Push Notifications`}</h1>
    <hr></hr>
    <p>{`To send ad-hoc push notification to your patron's device, follow these step-by-step instructions. `}<em parentName="p">{`Patron's need to opt in to receive any type of push notification from the app in order to receive them. If they are not opted in, push notifications will not be sent to their device.`}</em>{`:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps ection from the left side menu options. Select the Mobile App or Template you wish to send the notification from. `}</li>
      <li parentName="ol">{`Enable 'Notifications' under the Features section. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app or template. `}</li>
      <li parentName="ol">{`Open the app or template again. The 'Edit Notifications' button should now display next the to 'Notifications' toggle. Select this to open the Notifications configuration page. `}</li>
      <li parentName="ol">{`Select 'Add new Notification'`}</li>
      <li parentName="ol">{`Provide a title for the notification. `}</li>
      <li parentName="ol">{`Provide the body of the notification. `}</li>
      <li parentName="ol">{`Set where the patron will be directed when they interact with the notification. This can be set to your app's homescreen, a Tab, Channel or Content Item. `}</li>
      <li parentName="ol">{`Determine the audience for the notification. You can either send the notification to just the users of the specific mobile app / template or to all mobile apps and the associated templates. `}</li>
      <li parentName="ol">{`Decide when to send the notification. You can send it immediately or schedule it to be sent at a certain date and time. `}</li>
      <li parentName="ol">{`To send it at a planned date and time, select the date from the calendar and enter in a time in the 24-hour-clock format. `}</li>
      <li parentName="ol">{`Click 'Send'.  `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      